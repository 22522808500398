<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="label"
        >
          <template v-if="hasMultiple">
            <template v-for="mat in node.material">
              <InputWrapper
                type="button"
                :simple="true"
                :label="mat.name"
                :value="'Edit Material'"
                @change="viewMaterial(mat)"
              />
            </template>
          </template>

          <template v-else>
            <InputWrapper
              v-if="hasMaterial"
              type="button"
              :simple="true"
              :label="node.material.name"
              :value="'Edit Material'"
              @change="viewMaterial(node.material)"
            />
            <InputWrapper
              v-else
              type="info"
              :label="'No Material Set'"
              @change=""
            />
          </template>
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import SceneGraph from '@components/editor/scene/model/panels/SceneGraph'
import mixin from './NodeSettingMixin'

export default {
  name: 'MeshBlock',

  mixins: [mixin],

  computed: {

    hasArray () {
      return (Array.isArray(this.node.material))
    },

    materialCount () {
      if (this.hasArray) return this.node.material.length
      return 1
    },

    hasMultiple () {
      return this.materialCount > 1
    },

    label () {
      if (this.hasMultiple) return 'Materials (' + this.materialCount + ')'
      return 'Material'
    },

    hasMaterial () {
      return this.node.material !== null
    }

  },

  methods: {
    viewMaterial (node) {
      this.$bus.$emit('editor:sidebar', {
        component: SceneGraph,
        props: {
          key: 'node-' + node.uuid,
          node: node,
          gui: this.gui
        }
      })
    }
  }

}
</script>
